import React from 'react';
import styled, { css } from 'styled-components';

import useSettingItem from '@/hooks/useSettingItem';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ReactComponent as Logo } from '@/assets/svg/logo.svg';
import { ReactComponent as CloseIcon } from '@/assets/svg/close.svg';
import Checked from '@/assets/svg/checked.svg';
import Disabled from '@/assets/svg/checked-disabled.svg';
import Alert from '@/assets/svg/alert.svg';
import Button from '@/components/Button';

const SAVE_BUTTON = 'Сохранить мой выбор';

interface Props {
  analyticsCookiesChecked: boolean;
  onAnalyticsCookiesChange: (analyticsCookiesChecked: boolean) => void;
  onClose: () => void;
  onSave: () => void;
}

function CookieSettingsModal({
  analyticsCookiesChecked,
  onAnalyticsCookiesChange,
  onClose,
  onSave,
}: Props) {
  const сookieSettingsTitle = useSettingItem('COOKIE_SETTINGS_TITLE');
  const сookieSettingsText = useSettingItem('COOKIE_SETTINGS_TEXT');
  const сookieSettingsSystemCheckboxLabel = useSettingItem(
    'COOKIE_SETTINGS_SYSTEM_CHECKBOX_LABEL'
  );
  const сookieSettingsSystemCheckboxText = useSettingItem(
    'COOKIE_SETTINGS_SYSTEM_CHECKBOX_TEXT'
  );
  const сookieSettingsAnalyticsCheckboxLabel = useSettingItem(
    'COOKIE_SETTINGS_ANALYTICS_CHECKBOX_LABEL'
  );
  const сookieSettingsAnalyticsCheckboxText = useSettingItem(
    'COOKIE_SETTINGS_ANALYTICS_CHECKBOX_TEXT'
  );
  const сookieSettingsAnalyticsCheckboxDisclaimer = useSettingItem(
    'COOKIE_SETTINGS_ANALYTICS_CHECKBOX_DISCLAIMER'
  );

  return (
    <Wrapper>
      <Component>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <CloseButton
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon />
        </CloseButton>
        <Content>
          <Title>{сookieSettingsTitle}</Title>
          <Text
            dangerouslySetInnerHTML={{ __html: сookieSettingsText as string }}
          />
          <CookiesSettings>
            <CookiesSettingItem>
              <CheckboxWrapper>
                <Checkbox
                  onChange={() => {}}
                  checked
                  type="checkbox"
                  disabled
                />
              </CheckboxWrapper>
              <Description>
                <DescriptionTitle>
                  {сookieSettingsSystemCheckboxLabel}
                </DescriptionTitle>
                <DescriptionText>
                  {сookieSettingsSystemCheckboxText}
                </DescriptionText>
              </Description>
            </CookiesSettingItem>
            <CookiesSettingItem>
              <CheckboxWrapper>
                <Checkbox
                  onChange={() => {
                    onAnalyticsCookiesChange(!analyticsCookiesChecked);
                  }}
                  type="checkbox"
                  checked={analyticsCookiesChecked}
                />
              </CheckboxWrapper>
              <Description>
                <DescriptionTitle>
                  {сookieSettingsAnalyticsCheckboxLabel}
                </DescriptionTitle>
                <DescriptionText>
                  {сookieSettingsAnalyticsCheckboxText}
                </DescriptionText>
              </Description>
            </CookiesSettingItem>
            {!analyticsCookiesChecked && (
              <Disclamer
                dangerouslySetInnerHTML={{
                  __html: сookieSettingsAnalyticsCheckboxDisclaimer as string,
                }}
              />
            )}
          </CookiesSettings>
        </Content>
        <ActionButton
          variant="orange"
          onClick={() => {
            onSave();
          }}
        >
          {SAVE_BUTTON}
        </ActionButton>
      </Component>
    </Wrapper>
  );
}

export default CookieSettingsModal;

const LogoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50px;
  width: 108px;
  min-height: 119px;
  background: ${colors.orange};

  padding: 16px 15px;
  transition: all 0.3s ease-in-out;
  z-index: 25;

  ${media.mobile(css`
    width: 84px;
    min-height: 93px;
    padding: 17px 12px 12px;
    left: 36px;
  `)}

  ${media.mobileSmall(css`
    width: 79px;
    min-height: 87px;
    padding: 20px 11px 12px;
    left: 22px;
  `)}

  svg {
    width: 76px;
    height: 76px;
    pointer-events: none;

    ${media.mobile(css`
      width: 60px;
      height: 59px;
    `)}

    ${media.mobileSmall(css`
      width: 57px;
      height: 55px;
    `)}
  }

  ${media.laptopUp(css`
    &:hover {
      padding-top: 80px;
    }
  `)}
`;

const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1;
  border-radius: 50%;
  padding: 15px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  ${media.laptop(css`
    top: 15px;
    right: 15px;
  `)}

  ${media.mobileSmall(css`
    padding: 10px;
    right: 9px;
    top: 10px;
  `)}

  svg {
    width: 20px;
    height: 20px;
    display: block;
    fill: currentColor;

    ${media.mobileSmall(css`
      width: 14px;
      height: 14px;
    `)}
  }
`;

const Wrapper = styled.div`
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  background: ${colors.black50};
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const Component = styled.div`
  background: ${colors.white};
  padding: 143px 50px 42px;
  max-width: 894px;
  position: relative;

  ${media.tablet(css`
    padding: 127px 50px 39px;
    max-width: 672px;
  `)}

  ${media.mobile(css`
    padding: 101px 36px 30px;
    max-width: 596px;
  `)}

  ${media.mobileSmall(css`
    padding: 105px 22px 21px;
    max-width: 320px;
  `)}
`;

const Content = styled.div`
  height: 635px;
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.375rem;

    &-track {
      background-color: transparent;
    }

    &-thumb {
      background-color: ${colors.orange};

      &:hover {
        background-color: ${colors.orange100};
      }
    }
  }

  ${media.tablet(css`
    height: 354px;
  `)}

  ${media.mobile(css`
    height: 489px;
  `)}

  ${media.mobileSmall(css`
    height: 309px;
  `)}
`;

const Title = styled.h3`
  color: ${colors.black};
  font-size: 23px;
  font-weight: 700;
  line-height: 25px;
  text-transform: uppercase;
  margin-bottom: 20px;

  ${media.tablet(css`
    font-size: 20px;
  `)}

  ${media.mobile(css`
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 15px;
  `)}

  ${media.mobileSmall(css`
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 10px;
  `)}
`;

const Text = styled.div`
  strong {
    font-size: 18px;
    line-height: 26px;
    color: ${colors.orange};

    ${media.tablet(css`
      font-size: 16px;
      line-height: 24px;
    `)}

    ${media.mobile(css`
      font-size: 14px;
      line-height: 22px;
    `)}

    ${media.mobileSmall(css`
      font-size: 13px;
      line-height: 16px;
    `)}
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;

    ${media.tablet(css`
      font-size: 14px;
      line-height: 22px;
    `)}

    ${media.mobile(css`
      font-size: 13px;
      line-height: 18px;
    `)}

     ${media.mobileSmall(css`
      font-size: 12px;
      line-height: 16px;
    `)}
  }
`;

const CookiesSettings = styled.div`
  margin-top: 20px;
`;

const CookiesSettingItem = styled.div`
  display: flex;

  :not(:last-child) {
    margin-bottom: 20px;
  }
`;

const CheckboxWrapper = styled.div`
  width: 20px;
  height: 20px;

  ${media.mobileSmall(css`
    width: 17px;
    height: 17px;
  `)}
`;

const Checkbox = styled.input`
  border: 1px solid ${colors.gray120};
  border-radius: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;

  ${media.mobileSmall(css`
    width: 17px;
    height: 17px;
  `)}

  &:checked {
    border: none;
    background: ${`url(${Checked})`};

    ${media.mobileSmall(css`
      background-size: 17px;
      background-position: center center;
    `)}
  }

  &:disabled {
    border: none;
    background: ${`url(${Disabled})`};

    ${media.mobileSmall(css`
      background-size: 17px;
      background-position: center center;
    `)}
  }
`;

const Description = styled.div`
  margin-left: 12px;
  width: auto;

  ${media.mobileSmall(css`
    margin-left: 9px;
  `)}
`;

const DescriptionTitle = styled.h4`
  font-size: 14px;
  margin-bottom: 10px;

  ${media.mobileSmall(css`
    font-size: 13px;
    line-height: 16px;
  `)}
`;

const DescriptionText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  ${media.mobileSmall(css`
    font-size: 12px;
    line-height: 16px;
  `)}
`;

const Disclamer = styled.div`
  padding: 14px 9px 10px 47px;
  background: ${colors.orange200} url(${Alert}) no-repeat 10px 10px;

  p {
    font-size: 12px;
    line-height: 17px;
  }

  ${media.mobileSmall(css`
    padding: 14px 4px 9px 35px;
    background-size: 24px;
    background-position: 5px 10px;
  `)}
`;

const ActionButton = styled(Button)`
  min-width: 208px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin-top: 29px;

  ${media.tablet(css`
    min-width: 193px;
    font-size: 14px;
    line-height: 22px;
  `)}

  ${media.mobileSmall(css`
    margin-top: 19px;
    min-width: 161px;
    font-size: 12px;
    line-height: 12px;
  `)}
`;
