import React, { useRef } from 'react';
import styled, { css } from 'styled-components';

import { getSettingValueByKey } from '@tager/web-modules';

import { StringField } from '@/typings/model';
import { media } from '@/utils/mixin';
import { useTypedSelector } from '@/store/store';
import { selectSettingItemList } from '@/store/reducers/tager/settings';
import { colors } from '@/constants/theme';

import ProductCard from './components/ProductCard';

type Props = {
  isWhite?: boolean;
};

function Products({ isWhite }: Props) {
  const productsRef = useRef<HTMLDivElement | null>(null);
  const settingsList = useTypedSelector(selectSettingItemList);

  const hotProducts = getSettingValueByKey(settingsList, 'HOT_PRODUCTS');

  return (
    <Component ref={productsRef} isWhite={isWhite}>
      <ComponentContainer>
        <ProductsInner>
          {hotProducts?.map((productItem, index) => {
            return (
              <ProductsCell key={index} size={productItem.design}>
                <ProductCard
                  product={productItem}
                  id={index}
                  productsRef={productsRef}
                />
              </ProductsCell>
            );
          })}
        </ProductsInner>
      </ComponentContainer>
    </Component>
  );
}

const Component = styled.div<{ isWhite?: boolean }>`
  padding: 80px 0 62px;

  ${(props) =>
    props.isWhite
      ? css`
          background: ${colors.white};
        `
      : ''}

  ${media.laptop(css`
    padding: 40px 0;
  `)}
  
  ${media.mobile(css`
    padding: 30px 0;
  `)}
`;

const ComponentContainer = styled.div`
  max-width: 1920px;
  margin: 0 auto;

  ${media.mobile(css`
    overflow-x: auto;
    overflow-y: hidden;

    scrollbar-color: ${colors.gray30} ${colors.gray80};
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      height: 4px;

      &-track {
        background-color: ${colors.gray80};
      }

      &-thumb {
        background-color: ${colors.gray30};
      }
    }
  `)}
`;

const ProductsInner = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(4, 25%);
  grid-auto-rows: calc(100vw / 100 * 54.68 / 2);

  @media (min-width: 1921px) {
    grid-auto-rows: calc(1920px / 100 * 54.68 / 2);
  }

  ${media.tabletSmall(css`
    grid-auto-flow: column dense;
    grid-template-columns: repeat(3, 33.33%);
    grid-auto-rows: calc(100vw / 100 * 80 / 3);
  `)}

  ${media.mobile(css`
    grid-template-rows: repeat(2, calc(100vw / 100 * 152 / 2));
    grid-template-columns: repeat(2, 50vw);
    grid-auto-columns: 50vw;
    grid-auto-flow: column dense;
    padding-bottom: 30px;
  `)}
`;

const ProductsCell = styled.div<{ size: StringField }>`
  ${(props) =>
    props.size === 'big'
      ? css`
          grid-row-end: span 2;

          ${media.tabletSmall(css`
            grid-row-end: span 3;
          `)}

          ${media.mobile(css`
            grid-row-end: span 1;
          `)}
        `
      : ``}
`;

export default Products;
