import React from 'react';
import styled, { css } from 'styled-components';

import { Nullish } from '@tager/web-core';

import BannerCard from '@/modules/Home/components/BannerCard';
import { HomePageType, ImageThumbnail, StringField } from '@/typings/model';
import { media } from '@/utils/mixin';

import SalesBtn from './components/SalesBtn';
import SalesSlider from './components/SalesSlider';

type Props = {
  page: Nullish<HomePageType>;
};

function Hero({ page }: Props) {
  function getListLength(
    list: Array<{
      image: ImageThumbnail;
      link: StringField;
      title: StringField;
    }>
  ) {
    let listLength = 0;
    list.forEach((listItem) => {
      if (!listItem.image && !listItem.title && !listItem.link) return;
      listLength += 1;
    });
    return listLength;
  }

  return (
    <Component>
      <HeroBackground />
      <HeroContainer>
        <HeroInner>
          <HeroLeft>
            <SalesBtn
              label={page?.templateFields.firstBlockCircleLabel ?? ''}
              link={page?.templateFields.firstBlockCircleLink ?? ''}
            />
            <HeroLeftBottom>
              <HeroLeftBottomInner>
                <BannerCard
                  image={page?.templateFields.firstBlockLeftImage}
                  text={page?.templateFields.firstBlockLeftLabel ?? ''}
                  link={page?.templateFields.firstBlockLeftLink ?? ''}
                  isLink={true}
                />
              </HeroLeftBottomInner>
            </HeroLeftBottom>
          </HeroLeft>
          <HeroCenter>
            {getListLength(page?.templateFields.firstBlockCenterBlocks ?? []) <=
            1 ? (
              page?.templateFields.firstBlockCenterBlocks.map((salesItem) => {
                return (
                  <BannerCard
                    image={salesItem.image}
                    text={salesItem.title}
                    link={salesItem.link}
                  />
                );
              })
            ) : (
              <SalesSlider
                salesList={page?.templateFields.firstBlockCenterBlocks}
              />
            )}
          </HeroCenter>
          <HeroRight>
            <BannerCard
              image={page?.templateFields.firstBlockRightImage}
              title={page?.templateFields.firstBlockRightTitle}
              text={page?.templateFields.firstBlockRightText ?? ''}
              link={page?.templateFields.firstBlockRightLink ?? ''}
              isLink={true}
            />
          </HeroRight>
        </HeroInner>
      </HeroContainer>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
`;

const HeroContainer = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`;

const HeroInner = styled.div`
  display: flex;
  padding-top: 36px;
  height: calc(100vw / 100 * 49.12 + 36px);
  overflow: hidden;

  @media (min-width: 1921px) {
    height: calc(1920px / 100 * 49.12 + 36px);
  }

  ${media.laptop(css`
    padding-top: 48px;
    height: calc(100vw / 100 * 50.23 + 48px);
  `)}

  ${media.tabletSmall(css`
    padding-top: 54px;
    height: auto;
    flex-wrap: wrap;
  `)}
  
  ${media.mobileLarge(css`
    padding-top: 0;
  `)}
`;

const HeroBackground = styled.div`
  position: absolute;
  top: -100px;
  bottom: 0;
  left: 0;
  width: 100%;
  background: radial-gradient(
    81.25% 28416.66% at 38.67% 18.75%,
    #e0ff24 0%,
    #ffffff 100%
  );
`;

const HeroLeft = styled.div`
  position: relative;
  flex: 1 1 449px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${media.laptop(css`
    flex: 1 1 322px;
    max-width: 25.15%;
  `)}

  ${media.tabletSmall(css`
    flex: 0 0 100%;
    max-width: 100%;
    order: 5;
  `)}
`;

const HeroCenter = styled.div`
  position: relative;
  height: 100%;

  flex: 1 1 483px;
  max-width: 483px;

  ${media.laptop(css`
    flex: 1 1 322px;
    max-width: 25.15%;
  `)}

  ${media.tabletSmall(css`
    flex: 1 1 248px;
    max-width: 32.2%;
    height: calc(100vw / 100 * 64.84);
  `)}
  
  ${media.mobile(css`
    height: calc(100vw / 100 * 70);
  `)}
  
    
  ${media.mobileLarge(css`
    flex: 1 1 100%;
    max-width: 450px;
    margin: 0 auto;
    height: calc(var(--vh, 1vh) * 100 - 136px);
  `)}
  
  ${media.mobileMedium(css`
    flex: 1 1 100%;
    max-width: 100%;
    background: #7ed9da;
  `)}
`;

const HeroRight = styled.div`
  position: relative;
  flex: 1 1 944px;
  max-width: 944px;

  ${media.laptop(css`
    flex: 1 1 636px;
  `)}

  ${media.tabletSmall(css`
    flex: 1 1 520px;
    height: calc(100vw / 100 * 64.84);
  `)}
  
  ${media.mobile(css`
    max-width: 67.8%;
    height: calc(100vw / 100 * 70);
  `)}
  
  ${media.mobileLarge(css`
    display: none;
  `)}
`;

const HeroLeftBottom = styled.div`
  width: 100%;
  position: relative;
  flex: 1 1 auto;

  ${media.tabletSmall(css`
    display: none;
  `)}
`;
const HeroLeftBottomInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default Hero;
