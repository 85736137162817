import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import Breadcrumbs from '@/components/Breadcrumbs';
import TickerLineSection from '@/modules/Hypermarkets/components/TickerLineSection';
import { KoronaDomPageType } from '@/typings/model';
import News from '@/modules/Hypermarkets/components/News';
import Cooking from '@/modules/OwnProduction/components/Cooking';
import Subscription from '@/components/Subscription';
import ShoppingCenterShops from '@/components/ShoppingCenterShops';
import { media } from '@/utils/mixin';
import InformationSection from '@/modules/ShoppingCenterSingle/components/InformationSection';
import useCurrentPage from '@/hooks/useCurrentPage';
import TickerLine from '@/components/TickerLine';
import { useLayout } from '@/components/Layout/Layout.hooks';
// import useCurrentNewsList from '@/hooks/useCurrentNewsList';
import useCurrentChildrenPages from '@/hooks/useCurrentChildrenPages';
import { breakpoints } from '@/constants/theme';
import { setBreadCrumbsList } from '@/utils/common';
import { BreadCrumbs } from '@/typings/common';
import { NewsListItem } from '@/typings/model';
import { getNewsListByShop } from '@/services/requests';

import Hero from './components/Hero';
import BannerSection from './components/BannersSection';

function KаronaDom() {
  const page = useCurrentPage<KoronaDomPageType>();
  const pageFields = page?.templateFields;

  const isMobile = useMedia(`(max-width: ${breakpoints.tabletSmall - 1}px)`);

  const { setThemePage, setFooterInformation } = useLayout();
  setThemePage('');

  const { data: ideasList } = useCurrentChildrenPages('ideas-single');
  // const { data: newsList } = useCurrentNewsList('korona-mebel');

  const [newsList, setNewsList] = useState<Array<NewsListItem>>([]);

  useEffect(() => {
    getNewsListByShop(`filter[korona-mebel]=${page?.id}`).then((response) => {
      if (!response.data) return;
      setNewsList(response.data);
    });
  }, []);

  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (page?.templateFields.footerInformation) {
      setFooterInformation(page.templateFields.footerInformation);
    }
    return () => {
      setFooterInformation('');
    };
  }, [page?.templateFields]);

  if (!pageFields) return null;

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: `${page.title}`,
      path: `${page.path}`,
    },
    page?.parent
  );

  const {
    shopsItems,
    shopsTitle,
    tickers,
    ideasTitle,
    ideasUrl,
    ideasLinkLabel,
    contactsTickerBackground,
    contactsTickerColor,
    contactsTickerText,
    contactsTickerLink,
    contactsItems,
    contactsAddress,
    contactsBadge,
    contactsMap,
  } = pageFields;

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
      <Hero text={page?.excerpt ?? ''} image={page?.image} />

      <BannerSection page={page} />

      <ShoppingCenterShopsWrapper>
        <ShoppingCenterShops title={shopsTitle} categoriesList={shopsItems} />
      </ShoppingCenterShopsWrapper>

      <TickerLineSection tickers={tickers} />

      {newsList.length > 0 && (
        <NewsWrapper>
          <News newsList={newsList} />
        </NewsWrapper>
      )}

      {ideasList.length > 0 && (
        <Cooking
          recipesList={ideasList}
          title={ideasTitle ?? ''}
          btnLink={ideasLinkLabel ?? ''}
          btnLabel={ideasUrl ?? ''}
        />
      )}

      {contactsTickerText && (
        <TickerLineWrapper>
          <TickerLine
            backgroundTicket={contactsTickerBackground}
            colorTicket={contactsTickerColor}
            labelTicket={contactsTickerText}
            linkTicket={contactsTickerLink}
            rotateTicket={isMobile ? '-10' : '-5'}
            sizeTicket={'largest'}
          />
        </TickerLineWrapper>
      )}

      <InformationSection
        mapRef={mapRef}
        storeAddress={contactsAddress}
        storeContacts={contactsItems}
        contactsBadge={contactsBadge}
        coordinates={contactsMap}
      />
      <Subscription />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;

  ${media.tablet(css`
    margin-top: 20px;
  `)}

  ${media.mobile(css`
    margin-top: 0;
  `)}
`;

const ShoppingCenterShopsWrapper = styled.div`
  margin: 78px 0 148px;

  ${media.laptop(css`
    margin-bottom: 97px;
  `)}

  ${media.tablet(css`
    margin: 72px 0 52px;
  `)}

  ${media.mobile(css`
    margin: 50px 0 70px;
  `)}
`;

const NewsWrapper = styled.div`
  margin: 150px 0 -18px;

  ${media.laptop(css`
    margin: 80px 0 -40px;
  `)}

  ${media.tablet(css`
    margin: 30px 0 0;
  `)}

  ${media.mobile(css`
    margin-top: 70px;
  `)}
`;

const TickerLineWrapper = styled.div`
  margin-bottom: -204px;

  ${media.laptop(css`
    margin: -125px 0 -138px;
  `)}

  ${media.mobile(css`
    margin: -125px 0 -91px;
  `)}
`;

export default KаronaDom;
