import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { StringField } from '@/typings/model';
import { ReactComponent as ArrowDown } from '@/assets/svg/arrow-down-small.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  accordItem: {
    title: StringField;
    text: StringField;
  };
  index: number;
};

function ProcedureAccord({ accordItem, index }: Props) {
  const { text, title } = accordItem;

  const accordContentRef = useRef<HTMLDivElement>(null);

  const [isOpen, setOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);

  function toggleOpened(accordionId: number) {
    setOpen((opened) => !opened);
  }

  useEffect(() => {
    if (!accordContentRef.current) return;

    const currentHeight = accordContentRef.current.offsetHeight;
    setMaxHeight(currentHeight + 100);
  }, []);

  return (
    <Component id={String(index)}>
      <Inner>
        <Top onClick={() => toggleOpened(index)} isActive={isOpen}>
          <Title>{title}</Title>
          <ArrowDown />
        </Top>
        <Description isActive={isOpen} maxHeight={maxHeight}>
          <DescriptionInner ref={accordContentRef}>
            <Text>{text}</Text>
          </DescriptionInner>
        </Description>
      </Inner>
    </Component>
  );
}

const Inner = styled.div``;

const Title = styled.span`
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: ${colors.white80};
  max-width: 90%;
  transition: all 0.5s ease-in-out;
`;

const Top = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  cursor: pointer;

  svg {
    position: relative;
    right: 5px;
    width: 14px;
    height: 14px;
    transition: all 0.4s ease-in-out;
    color: ${colors.white};

    ${(props) =>
      props.isActive
        ? css`
            transform: rotate(-180deg);
          `
        : ''}
  }
`;

const Description = styled.div<{ isActive?: boolean; maxHeight?: number }>`
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.4s linear, opacity 0.3s linear;

  ${(props) =>
    props.isActive
      ? css`
          visibility: visible;
          opacity: 1;
          max-height: ${props.maxHeight ? props.maxHeight + 'px' : 'none'};
        `
      : ''}
`;

const DescriptionInner = styled.div`
  padding-bottom: 14px;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.white};
`;

const Component = styled.div`
  border-bottom: 1px solid ${colors.grayLight};

  &:last-child {
    border-bottom: none;

    ${DescriptionInner} {
      padding-bottom: 0;
    }
  }
`;

export default ProcedureAccord;
