import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import Breadcrumbs from '@/components/Breadcrumbs';
import { media } from '@/utils/mixin';
import InformationLine from '@/components/InformationLine';
import NotificationsCard from '@/components/NotificationsCard';
import Subscription from '@/components/Subscription';
import News from '@/modules/Hypermarkets/components/News';
import Brands from '@/components/Brands';
import useCurrentPage from '@/hooks/useCurrentPage';
import { NewsListItem, ShoppingCenterSinglePageType } from '@/typings/model';
import { getNewsListByShop } from '@/services/requests';
import { useLayout } from '@/components/Layout/Layout.hooks';
import ShoppingCenterShops from '@/components/ShoppingCenterShops';
import { setBreadCrumbsList } from '@/utils/common';
import { BreadCrumbs } from '@/typings/common';

import Hero from './components/Hero';
import BannerSection from './components/BannerSection';
import TickerLineSection from './components/TickerLineSection';
import ShopsSection from './components/ShopsSection';
import InformationSection from './components/InformationSection';

function ShoppingCenterSingle() {
  const mapRef = useRef<HTMLDivElement>(null);
  const shoppingCenter = useCurrentPage<ShoppingCenterSinglePageType>();

  const { setFooterInformation, setThemePage } = useLayout();
  setThemePage('');

  const [newsList, setNewsList] = useState<Array<NewsListItem>>([]);

  useEffect(() => {
    getNewsListByShop(`filter[trade-center]=${shoppingCenter?.id}`).then(
      (response) => {
        if (!response.data) return;
        setNewsList(response.data);
      }
    );
  }, []);

  useEffect(() => {
    setFooterInformation(
      shoppingCenter?.templateFields.footerInformation ?? ''
    );
    return () => {
      setFooterInformation('');
    };
  }, [shoppingCenter]);

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: `${shoppingCenter?.templateFields.topTitle}, ${shoppingCenter?.templateFields.topCity}, ${shoppingCenter?.templateFields.topAddress}`,
      path: `${shoppingCenter?.path}`,
    },
    shoppingCenter?.parent
  );

  if (!shoppingCenter?.templateFields) return null;

  const {
    brandsTitle,
    brandsList,
    shopsItems,
    shopsTitle,
    contactsAddress,
    contactsBadge,
    contactsItems,
    contactsMap,
    tickers,
  } = shoppingCenter?.templateFields;

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />
      <Hero shoppingCenter={shoppingCenter ?? null} mapRef={mapRef} />

      <InformationLine
        backgroundLine={
          shoppingCenter?.templateFields.informationLineBackground ?? ''
        }
        colorLine={shoppingCenter?.templateFields.informationLineColor ?? ''}
        fieldLine={shoppingCenter?.templateFields.informationLineField}
      />

      <BannerSection shoppingCenter={shoppingCenter ?? null} />
      <NotificationWrapper>
        <NotificationsCard
          link={shoppingCenter?.templateFields.bannerLink ?? ''}
          image={shoppingCenter?.templateFields.bannerImage}
        />
      </NotificationWrapper>

      {tickers?.length > 0 && <TickerLineSection tickers={tickers} />}

      <ShopsSectionWrapper>
        <ShopsSection
          shops={shoppingCenter?.templateFields.otherStores ?? []}
        />
      </ShopsSectionWrapper>

      {shoppingCenter?.templateFields.brandsList.length !== 0 ? (
        <Brands title={brandsTitle} list={brandsList} />
      ) : null}

      <ShoppingCenterShopsWrapper>
        <ShoppingCenterShops title={shopsTitle} categoriesList={shopsItems} />
      </ShoppingCenterShopsWrapper>

      {newsList.length > 0 && <News newsList={newsList} />}

      <InformationSection
        contactsBadge={contactsBadge}
        storeAddress={contactsAddress}
        storeContacts={contactsItems}
        mapRef={mapRef}
        isCross={true}
        coordinates={contactsMap}
      />
      <Subscription />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
`;

const NotificationWrapper = styled.div`
  max-width: 1680px;
  margin: 100px auto 0;
  padding: 0 40px;

  ${media.laptop(css`
    margin-top: 80px;
  `)}

  ${media.tablet(css`
    margin-top: 30px;
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    padding: 0 1px;
  `)}
`;

const ShopsSectionWrapper = styled.div`
  margin: -70px 0 120px;

  ${media.laptop(css`
    margin: -58px 0 81px;
  `)}

  ${media.tablet(css`
    margin: 24px 0 60px;
  `)}

  ${media.mobile(css`
    margin: 4px 0 50px;
  `)}
`;

const ShoppingCenterShopsWrapper = styled.div`
  margin: 78px 0 148px;

  ${media.laptop(css`
    margin-bottom: 97px;
  `)}

  ${media.tablet(css`
    margin: 70px 0 50px;
  `)}

  ${media.mobile(css`
    margin: 50px 0 60px;
  `)}
`;

export default ShoppingCenterSingle;
