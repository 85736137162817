import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import useCurrentPage from '@/hooks/useCurrentPage';
import { CastleTmPageType } from '@/typings/model';
import Hero from '@/modules/OwnProduction/components/Hero';
import Cooking from '@/modules/OwnProduction/components/Cooking';
import Features from '@/modules/OwnProduction/components/Features';
import Subscription from '@/components/Subscription';
import { useLayout } from '@/components/Layout/Layout.hooks';
import useCurrentChildrenPages from '@/hooks/useCurrentChildrenPages';

import InformationBlock from '../OwnProduction/components/InformationBlock';

import ProductsSlider from './components/ProductsSlider';

function CastleTm() {
  const page = useCurrentPage<CastleTmPageType>();
  const { data: recipesList } = useCurrentChildrenPages('recipes-single');
  const { setThemePage } = useLayout();

  setThemePage('');

  if (!page) return <></>;

  const {
    topTitle,
    topText,
    topStickerText,
    topStickerLink,
    features,
    featuresTitle,
    bannerTitle,
    bannerText,
    bannerImage,
    bannerLink,
    products,
  } = page?.templateFields;

  return (
    <Wrapper>
      <Inner>
        <Hero
          title={topTitle}
          stickerLabel={topStickerText}
          stickerLink={topStickerLink}
          text={topText}
        />
        {products.length > 0 && <ProductsSlider products={products} />}
        {features.length > 0 && (
          <Features features={features} featuresTitle={featuresTitle} />
        )}
        <InformationBlockWrapper>
          <InformationBlock
            image={bannerImage}
            title={bannerTitle}
            text={bannerText}
            link={bannerLink}
          />
        </InformationBlockWrapper>
        {recipesList.length > 0 && (
          <CookingWrapper>
            <Cooking
              recipesList={recipesList}
              title={'Готовим вместе'}
              btnLink={'/our-production/recipes'}
              btnLabel={'Все рецепты'}
            />
          </CookingWrapper>
        )}
      </Inner>

      <Subscription background={colors.gray50} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
`;

const Inner = styled.div`
  margin: 17px 0 245px;

  ${media.laptop(css`
    margin: 45px 0 282px;
  `)}

  ${media.tablet(css`
    margin-bottom: 100px;
  `)}
`;

const InformationBlockWrapper = styled.div`
  ${media.tablet(css`
    margin-bottom: 70px;
  `)}
`;

const CookingWrapper = styled.div`
  ${media.tablet(css`
    margin-bottom: -147px;
  `)}
`;

export default CastleTm;
