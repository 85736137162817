import React, { RefObject } from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Cross from '@/components/Sticker/Cross';
import Button from '@/components/Button';
import { StringField } from '@/typings/model';
import SingleMap from '@/components/SingleMap';

import InfoItem from './components/InfoItem';

interface Props {
  mapRef: RefObject<HTMLDivElement>;
  storeAddress: StringField;
  storeContacts: Array<{
    name: StringField;
    text: StringField;
  }> | null;
  isCross?: boolean;
  contactsBadge?: StringField;
  coordinates: { latitude: number; longitude: number };
}

function InformationSection({
  mapRef,
  storeAddress,
  storeContacts,
  isCross,
  contactsBadge,
  coordinates,
}: Props) {
  return (
    <Component ref={mapRef}>
      <Inner>
        <Left>
          <LeftContent>
            {storeAddress && <Address>{storeAddress}</Address>}
            <ListInfo>
              {storeContacts?.map((contact) => {
                return (
                  <InfoItem
                    key={contact.name}
                    label={contact.name ?? ''}
                    text={contact.text ?? ''}
                  />
                );
              })}
            </ListInfo>
          </LeftContent>
        </Left>
        <Right>
          <MapWrapper>
            {contactsBadge && <MapBtn>{contactsBadge}</MapBtn>}
            <MapInner>
              <MapCircle>
                <SingleMap currentCoordinates={coordinates} />
              </MapCircle>
            </MapInner>
          </MapWrapper>
        </Right>
      </Inner>
      {isCross && (
        <CrossWrapper>
          <Cross isSecondary={true} />
        </CrossWrapper>
      )}
    </Component>
  );
}

const Component = styled.div`
  max-width: 1920px;
  margin: 141px auto 0;
  padding-bottom: 167px;
  position: relative;
  z-index: 3;

  &:before {
    position: absolute;
    content: '';
    top: 164px;
    left: 0;
    height: 100%;
    width: 100%;
    background: ${colors.black};

    ${media.laptop(css`
      top: 136px;
    `)}

    ${media.tablet(css`
      top: 98px;
    `)}
    
    ${media.mobile(css`
      top: 84px;
    `)}
  }

  ${media.laptop(css`
    margin-top: 104px;
    padding-bottom: 192px;
  `)}

  ${media.tablet(css`
    margin-top: 98px;
    padding-bottom: 105px;
  `)}
  
  ${media.mobile(css`
    padding-bottom: 127px;
    z-index: 2;
  `)}
`;

const Inner = styled.div`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;
  position: relative;

  display: flex;
  justify-content: space-between;

  ${media.tablet(css`
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    flex-direction: column-reverse;
  `)}
`;

const Left = styled.div`
  flex: 1 1 648px;
  max-width: 648px;

  ${media.laptop(css`
    flex: 1 1 486px;
    max-width: 486px;
  `)}

  ${media.tablet(css`
    flex: 0 0 346px;
    max-width: 346px;
    margin-right: 58px;
  `)}
  
  ${media.mobile(css`
    flex: 1 1 auto;
    margin: 10px 0 0;
  `)}
`;
const Right = styled.div`
  flex: 1 1 920px;
  max-width: 920px;

  ${media.laptop(css`
    flex: 1 1 690px;
    max-width: 690px;
  `)}

  ${media.tablet(css`
    flex: 0 0 582px;
    max-width: 582px;
  `)}
  
  ${media.mobile(css`
    margin-left: 85px;
    flex: 0 0 372px;
    width: 372px;
  `)}
`;

const LeftContent = styled.div`
  padding-top: 314px;

  ${media.laptop(css`
    padding-top: 216px;
  `)}

  ${media.tablet(css`
    padding-top: 146px;
  `)}
  
  ${media.mobile(css`
    padding-top: 0;
  `)}
`;

const MapWrapper = styled.div`
  border-radius: 100%;
  width: 100%;
  position: relative;
  background: ${colors.gray50};

  &:before {
    display: block;
    content: '';
    padding-bottom: 100%;
  }
`;

const MapInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const MapCircle = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  overflow: hidden;

  .ymaps-2-1-79-map {
    border-radius: 100%;
    overflow: hidden;
  }
`;

const MapBtn = styled(Button)`
  position: absolute;
  content: '';
  top: 0;
  left: -47px;
  width: 322px;
  height: 322px;
  background: ${colors.orange};
  border-radius: 100%;
  padding: 0;
  transform: rotate(15deg);
  z-index: 10;
  transition: all 0.3s linear;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  color: ${colors.white};
  pointer-events: none;

  ${media.laptop(css`
    top: 16px;
    left: -60px;
    width: 240px;
    height: 240px;

    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    top: -71px;
    left: 58px;
    width: 170px;
    height: 170px;

    font-size: 20px;
    line-height: 26px;
  `)}
  
  ${media.mobile(css`
    top: -6px;
    left: -85px;
    width: 150px;
    height: 150px;

    font-size: 16px;
    line-height: 20px;
    transform: rotate(-15deg);
  `)}
`;

const Address = styled.h3`
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.white};
  margin: 0 0 80px;

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
    margin-bottom: 25px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 50px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 20px;
  `)}
`;
const ListInfo = styled.div``;

const CrossWrapper = styled.div`
  position: absolute;
  top: -52px;
  left: -118px;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.6));
  height: 320px;
  width: 330px;

  ${media.laptop(css`
    top: -35px;
    left: -110px;
    height: 260px;
    width: 255px;
  `)}

  ${media.tablet(css`
    display: none;
  `)}
`;

export default InformationSection;
