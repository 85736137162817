import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import InformationBlock from '@/modules/OwnProduction/components/InformationBlock';
import News from '@/modules/Hypermarkets/components/News';
import { media } from '@/utils/mixin';
import TickerLine from '@/components/TickerLine';
import InformationSection from '@/modules/ShoppingCenterSingle/components/InformationSection';
import Subscription from '@/components/Subscription';
import { breakpoints, colors } from '@/constants/theme';
import TickerLineSection from '@/modules/ShoppingCenterSingle/components/TickerLineSection';
import Brands from '@/components/Brands';
import NotificationsCard from '@/components/NotificationsCard';
import Breadcrumbs from '@/components/Breadcrumbs';
import Hero from '@/modules/ShoppingCenterSingle/components/Hero';
import useCurrentPage from '@/hooks/useCurrentPage';
import { FashionableMollSinglePageType, NewsListItem } from '@/typings/model';
import { useLayout } from '@/components/Layout/Layout.hooks';
import { getNewsListByShop } from '@/services/requests';
import ShoppingCenterShops from '@/components/ShoppingCenterShops';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';

function FashionableMollSingle() {
  const mapRef = useRef<HTMLDivElement>(null);
  const fashionMoll = useCurrentPage<FashionableMollSinglePageType>();
  const isMobile = useMedia(`(max-width: ${breakpoints.tabletSmall - 1}px)`);

  const {
    setFooterInformation,
    setThemePage,
    setActiveStoreName,
    setMenuStore,
  } = useLayout();

  const [newsList, setNewsList] = useState<Array<NewsListItem>>([]);

  const headerMenuFashionMoll =
    useTypedSelector((state) =>
      selectMenuItemListByAlias(state, 'fashion-moll')
    ) ?? [];

  useEffect(() => {
    getNewsListByShop(`filter[fashion-mall]=${fashionMoll?.id}`).then(
      (response) => {
        if (!response.data) return;
        setNewsList(response.data);
      }
    );
  }, []);

  useEffect(() => {
    setThemePage('#E4FF41');

    setActiveStoreName({
      value: 'Модный молл',
      label: 'Модный молл',
    });
    setMenuStore(headerMenuFashionMoll);

    return () => {
      setActiveStoreName({
        value: '',
        label: '',
      });
      setMenuStore([]);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [headerMenuFashionMoll]);

  useEffect(() => {
    if (fashionMoll?.templateFields.footerInformation) {
      setFooterInformation(fashionMoll.templateFields.footerInformation);
      return () => {
        setFooterInformation('');
      };
    }
  }, [fashionMoll]);

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: `${fashionMoll?.templateFields.topTitle}, ${fashionMoll?.templateFields.topCity}, ${fashionMoll?.templateFields.topAddress}`,
      path: fashionMoll?.path ?? '',
    },
    fashionMoll?.parent
  );

  if (!fashionMoll?.templateFields) return null;

  const {
    brandsTitle,
    brandsList,
    firstBannerImage,
    firstBannerLink,
    bannerLink,
    bannerText,
    bannerTitle,
    bannerImage,
    tickerBlockTextThird,
    tickerBlockBackgroundThird,
    tickerBlockColorThird,
    tickerBlockLinkThird,
    shopsItems,
    shopsTitle,
    contactsAddress,
    contactsBadge,
    contactsItems,
    contactsMap,
    tickers,
  } = fashionMoll?.templateFields;

  return (
    <Wrapper>
      <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />

      <Hero
        shoppingCenter={fashionMoll ?? null}
        mapRef={mapRef}
        isSecondary={true}
        storeList={shopsItems}
      />

      <NotificationWrapper>
        <NotificationsCard
          link={firstBannerLink ?? ''}
          image={firstBannerImage}
        />
      </NotificationWrapper>

      {tickers?.length > 0 && <TickerLineSection tickers={tickers} />}

      {fashionMoll?.templateFields.brandsList.length !== 0 ? (
        <BrandsWrapper>
          <Brands title={brandsTitle} list={brandsList} />
        </BrandsWrapper>
      ) : null}

      <ShoppingCenterShopsWrapper>
        <ShoppingCenterShops
          title={shopsTitle}
          categoriesList={shopsItems}
          colorProgress={`#e4ff41`}
        />
      </ShoppingCenterShopsWrapper>

      <InformationBlockWrapper>
        <InformationBlock
          image={bannerImage}
          title={bannerTitle}
          text={bannerText}
          link={bannerLink}
          isStickerUp={true}
        />
      </InformationBlockWrapper>

      {newsList.length > 0 && (
        <NewsWrapper>
          <News newsList={newsList} />
        </NewsWrapper>
      )}

      <TickerLineWrapper>
        <TickerLine
          backgroundTicket={tickerBlockBackgroundThird ?? ''}
          colorTicket={tickerBlockColorThird ?? ''}
          labelTicket={tickerBlockTextThird ?? ''}
          linkTicket={tickerBlockLinkThird ?? ''}
          rotateTicket={isMobile ? '10' : '5'}
          sizeTicket={'largest'}
        />
      </TickerLineWrapper>
      <InformationSectionWrapper>
        <InformationSection
          mapRef={mapRef}
          storeAddress={contactsAddress}
          storeContacts={contactsItems}
          contactsBadge={contactsBadge}
          coordinates={contactsMap}
        />
      </InformationSectionWrapper>
      <Subscription background={colors.gray50} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
`;

const NotificationWrapper = styled.div`
  max-width: 1680px;
  margin: 100px auto 0;
  padding: 0 40px;

  ${media.laptop(css`
    margin-top: 80px;
  `)}

  ${media.tablet(css`
    margin-top: 52px;
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    margin-top: 30px;
    padding: 0 1px;
  `)}
`;

const BrandsWrapper = styled.div`
  margin: 62px 0 100px;

  ${media.laptop(css`
    margin: 25px 0 80px;
  `)}

  ${media.tablet(css`
    margin-bottom: 70px;
  `)}

  ${media.mobile(css`
    margin-bottom: 50px;
  `)}
`;

const ShoppingCenterShopsWrapper = styled.div`
  margin: 78px 0 148px;

  ${media.laptop(css`
    margin-bottom: 97px;
  `)}

  ${media.tablet(css`
    margin: 70px 0 50px;
  `)}

  ${media.mobile(css`
    margin: 50px 0 60px;
  `)}
`;

const InformationBlockWrapper = styled.div`
  margin: 72px 0 0;

  ${media.laptop(css`
    margin-top: 29px;
  `)}
`;

const NewsWrapper = styled.div`
  margin: 100px 0 205px;

  ${media.laptop(css`
    margin: 80px 0 150px;
  `)}

  ${media.tablet(css`
    margin: 50px 0 70px;
  `)}

  ${media.mobile(css`
    margin: 70px 0 78px;
  `)}
`;

const TickerLineWrapper = styled.div`
  z-index: 5;
  position: relative;
`;

const InformationSectionWrapper = styled.div`
  margin: -307px 0 0;

  ${media.laptop(css`
    margin-top: -244px;
  `)}

  ${media.tablet(css`
    margin-top: -158px;
  `)}

  ${media.mobile(css`
    margin-top: -150px;
  `)}
`;

export default FashionableMollSingle;
