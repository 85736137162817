import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { ReactComponent as ArrowRight } from '@/assets/svg/arrow-right.svg';
import InformationLabel from '@/components/InformationLabel';
import { TradeCenterItemType } from '@/typings/model';
import Link from '@/components/Link';

interface Props {
  activeShop?: TradeCenterItemType | null;
}

function ShopDescription({ activeShop }: Props) {
  return (
    <Component>
      <Inner>
        <Name>
          {activeShop?.name}, {activeShop?.address}
        </Name>
        <Services>
          {activeShop?.services.map((service, index) => {
            return (
              <ServicesCell key={index}>
                <InformationLabel
                  label={service.name}
                  isWhite={true}
                  isSmall={true}
                />
              </ServicesCell>
            );
          })}
        </Services>
        <Bottom>
          <BottomButton to={activeShop?.page.url}>
            <BtnText>Подробнее</BtnText>
            <ArrowRight />
          </BottomButton>
        </Bottom>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  width: 100%;

  ${media.tablet(css`
    margin-top: -50px;
  `)}
`;

const Inner = styled.div`
  padding: 104px 54px 70px;

  ${media.laptop(css`
    padding: 59px 40px;
  `)}

  ${media.tablet(css`
    padding: 100px 30px 50px;
  `)}
  
  ${media.mobile(css`
    padding: 80px 20px 50px;
  `)}
`;

const Name = styled.p`
  max-width: 475px;
  font-weight: 500;
  font-size: 50px;
  line-height: 58px;
  color: ${colors.white};
  margin-bottom: 77px;

  ${media.laptop(css`
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 40px;
  `)}

  ${media.tablet(css`
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 30px;
  `)}
  
  ${media.mobile(css`
    font-size: 28px;
    line-height: 34px;
  `)}
`;

const Services = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 272px;
  overflow: hidden;
  margin: -11px -11px 94px;

  ${media.laptop(css`
    margin: -8px -8px 51px;
  `)}

  ${media.tablet(css`
    margin-bottom: 25px;
  `)}
  
    ${media.mobile(css`
    margin: -8px -4px 42px;
  `)}
`;

const ServicesCell = styled.div`
  padding: 11px;

  ${media.laptop(css`
    padding: 8px;
  `)}

  ${media.mobile(css`
    padding: 8px 4px;
  `)}
`;

const Bottom = styled.div``;

const BottomButton = styled(Link)`
  display: inline-block;
  position: relative;
  padding: 18px 15px 18px 16px !important;
  cursor: pointer;

  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.white};
  transition: all 0.3s linear;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.orange};
    transform: skew(1deg, -3deg);
    transition: all 0.3s linear;
  }

  svg {
    position: relative;
    top: 2px;
    margin-left: 13px;
    width: 35px;
    height: 18px;

    ${media.laptop(css`
      margin-left: 8px;
      width: 18px;
      height: 15px;
    `)}
  }

  &:hover {
    &:before {
      background: ${colors.orangeHover};
    }
  }

  ${media.laptop(css`
    padding: 13px 16px 12px 12px !important;
    font-size: 16px !important;
    line-height: 24px !important;

    &:before {
      transform: skew(1deg, -3deg);
    }
  `)}
`;

const BtnText = styled.span`
  position: relative;
`;

export default ShopDescription;
