import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Clusterer, Map, Placemark, YMaps, YMapsApi } from 'react-yandex-maps';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { TradeCenterItemType } from '@/typings/model';
import PlacemarkIcon from '@/assets/svg/placemark.svg';
import useSettingItem from '@/hooks/useSettingItem';

import ShopDescription from './components/ShopDescription';

interface Props {
  activeShop?: TradeCenterItemType | null;
  placemarkList?: any;
  isActiveCity?: string;
  setActiveShop?: (shop: TradeCenterItemType) => void;
  isCircle?: boolean;
}

function MapBlock({
  activeShop,
  placemarkList,
  isActiveCity,
  setActiveShop,
  isCircle,
}: Props) {
  const [coordinates, setCoordinates] = useState<Array<number>>([53.9, 27.57]);
  const [zoom, setZoom] = useState<number>(13);
  const [ymapsCurrent, setYmapsCurrent] = useState<YMapsApi | null>(null);
  const [yandexMapInstance, setYandexMapInstance] = useState<any>(null);
  const [added, setAdded] = useState<string[]>([]);

  function getCoordinates(activeShop: TradeCenterItemType) {
    if (!activeShop || !activeShop.map) {
      setCoordinates([53.902284, 27.561831]);
    } else {
      setCoordinates([activeShop.map.latitude, activeShop.map.longitude]);
      setZoom(16);
    }
  }

  function setMapState(coordinates: Array<number>, zoom: number) {
    return {
      center: coordinates,
      zoom: zoom,
    };
  }

  function onPlacemarkClick(point: any) {
    if (!setActiveShop) return;
    setActiveShop(point);
  }

  const mapState = useMemo<{ center: Array<number>; zoom: number }>(
    () => setMapState(coordinates, zoom),
    [zoom, coordinates]
  );

  useEffect(() => {
    if (activeShop) {
      getCoordinates(activeShop);
    }
  }, [activeShop]);

  const handleApiAvaliable = (ymaps: YMapsApi) => {
    setYmapsCurrent(ymaps);
  };

  useEffect(() => {
    if (!ymapsCurrent || !yandexMapInstance) return;
    ymapsCurrent.geocode(isActiveCity).then(function (res: any) {
      setCoordinates(
        res.geoObjects.properties
          .get('metaDataProperty')
          .GeocoderResponseMetaData.Point.coordinates.reverse()
      );
      setZoom(11);
    });
  }, [ymapsCurrent, isActiveCity]);

  const yandexApiKey = useSettingItem('YANDEX_API_KEY') || '';

  return (
    <Component isCircle={isCircle}>
      <Inner>
        <ShopLocation>
          <ShopLocationInner>
            <YMaps
              query={{
                apikey: yandexApiKey,
              }}
            >
              <Map
                state={mapState}
                instanceRef={(instance) => setYandexMapInstance(instance)}
                options={{ autoFitToViewport: 'always' }}
                modules={['geolocation', 'geocode']}
                onLoad={(ymaps) => handleApiAvaliable(ymaps)}
              >
                <Clusterer
                  options={{
                    preset: 'islands#invertedDarkOrangeClusterIcons',
                    groupByCoordinates: false,
                  }}
                >
                  {placemarkList?.map((item: TradeCenterItemType) => {
                    if (!item.map) return false;
                    return (
                      <Placemark
                        key={item.map.latitude + '' + item.map.longitude}
                        geometry={[item.map.latitude, item.map.longitude]}
                        options={{
                          iconLayout: 'default#image',
                          iconImageHref: PlacemarkIcon,
                          iconImageSize: [160, 160],
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          onPlacemarkClick(item);
                        }}
                      />
                    );
                  })}
                </Clusterer>
              </Map>
            </YMaps>
          </ShopLocationInner>
        </ShopLocation>
        {activeShop && (
          <ShopDescriptionWrapper>
            <ShopDescription activeShop={activeShop} />
          </ShopDescriptionWrapper>
        )}
      </Inner>
    </Component>
  );
}

const Inner = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 106.6px;
  border: 1.3325px solid rgba(153, 153, 153, 0.6);

  ${media.laptop(css`
    border: 1px solid rgba(153, 153, 153, 0.4);
    border-radius: 80px;
  `)}
  ${media.tablet(css`
    display: block;
    border: none;
    border-radius: 48px;
  `)}
  ${media.mobile(css`
    border: none;
    border-radius: 40px;
  `)}
  &:before {
    display: block;
    content: '';
    padding-bottom: 53.25%;

    ${media.tablet(css`
      display: none;
    `)}
  }
`;

const ShopLocation = styled.div`
  flex: 1 1 auto;
  min-width: 50%;
  background: ${colors.black};

  ${media.tablet(css`
    overflow: hidden;
    height: 387px;
    width: 100%;
    border: 1px solid rgba(153, 153, 153, 0.6);
    border-radius: 48px;
  `)}

  ${media.mobile(css`
    border: none;
    border-radius: 40px;
  `)}

  ${media.mobileMedium(css`
    height: 100vw;
  `)}
`;

const ShopDescriptionWrapper = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  background: ${colors.black};

  ${media.tablet(css`
    flex: 0 0 100%;
    max-width: 100%;
  `)}
`;

const ShopLocationInner = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 54px;
  background: ${colors.gray50};

  ${media.tablet(css`
    border-radius: 48px;
  `)}
  ${media.mobile(css`
    border-radius: 40px;
  `)}
  div:first-child,
  .ymaps-2-1-78-map {
    width: 100% !important;
    height: 100% !important;
  }

  .ymaps-2-1-78-copyrights-pane {
    opacity: 0;
  }

  .ymaps-2-1-78-image,
  .ymaps-2-1-79-image {
    width: 160px !important;
    height: 160px !important;
    background-size: 160px !important;

    ${media.laptop(css`
      width: 120px !important;
      height: 120px !important;
      background-size: 120px !important;
    `)}

    ${media.tablet(css`
      width: 82px !important;
      height: 82px !important;
      background-size: 82px !important;
    `)}

    ${media.mobileMedium(css`
      width: 52px !important;
      height: 52px !important;
      background-size: 52px !important;
    `)}
  }
`;

const Component = styled.div<{ isCircle?: boolean }>`
  max-width: 1680px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    padding: 0;
  `)}

  ${(props) =>
    props.isCircle
      ? css`
          padding: 0;
          width: 100%;
          max-width: 100%;

          ${Inner} {
            border-radius: 0;
            border: none;

            &:before {
              padding-bottom: 100%;
            }
          }
        `
      : css``}
`;

export default MapBlock;
