import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import Breadcrumbs from '@/components/Breadcrumbs';
import SectionSlider from '@/components/SectionSlider';
import GalleryBlock from '@/components/GalleryBlock';
import {
  ImageThumbnail,
  RestaurantsItem,
  RestaurantsSinglePageType,
} from '@/typings/model';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import InformationSection from '@/modules/Salon/components/InformationSection';
import ShopsSection from '@/modules/ShoppingCenterSingle/components/ShopsSection';
import useCurrentPage from '@/hooks/useCurrentPage';
import { getRestaurantsView } from '@/services/requests';
import { useLayout } from '@/components/Layout/Layout.hooks';
import Subscription from '@/components/Subscription';
import { BreadCrumbs } from '@/typings/common';
import { setBreadCrumbsList } from '@/utils/common';

import TickerLineSection from '../ShoppingCenterSingle/components/TickerLineSection';

import { RestaurantType } from './types';
import Hero from './components/Hero';
import VacancySection from './components/VacancySection';

const getRestaurantTitle = (path: string): string => {
  return path.includes('amsterdam')
    ? RestaurantType.amsterdam
    : path.includes('pit-stop')
    ? RestaurantType.pitSop
    : '';
};

function RestaurantsSingle() {
  const page = useCurrentPage<RestaurantsSinglePageType>();
  const { setThemePage, setFooterInformation } = useLayout();

  const [activeRestaurants, setActiveRestaurants] = useState<RestaurantsItem>();

  useEffect(() => {
    getRestaurantsView(model_id).then((response) => {
      if (!response.data) return;
      setActiveRestaurants(response.data);
    });

    setThemePage('#CD2544');
    setFooterInformation(page?.templateFields.footerInformation ?? '');
    return () => {
      setFooterInformation('');
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const BREADCRUMB_LIST: Array<BreadCrumbs> = setBreadCrumbsList(
    {
      title: page?.title ?? '',
      path: page?.path ?? '',
    },
    page?.parent
  );

  function setSlider(slides: Array<ImageThumbnail> | null) {
    const newSlider: Array<string> = [];
    if (slides) {
      slides.forEach((slide) => {
        if (slide?.url) {
          newSlider.push(slide.url);
        }
      });
    }
    return newSlider;
  }

  if (!page?.templateFields) return null;

  const restaurantTitle = getRestaurantTitle(page.path ?? '');

  const {
    model_id,
    phone,
    timetable,
    otherStore,
    gallerySlider,
    galleryBottom,
    tickers,
  } = page?.templateFields;
  return (
    <Wrapper>
      <Inner>
        <Breadcrumbs breadcrumbs={BREADCRUMB_LIST} />

        <Hero
          menuUrl={activeRestaurants?.menuUrl ?? ''}
          name={page?.title}
          image={page?.image ?? null}
          address={activeRestaurants?.address ?? ''}
          city={activeRestaurants?.city ?? ''}
          menuLinks={activeRestaurants?.menu ?? []}
          workingTime={timetable ?? ''}
        />

        {tickers?.length > 0 && <TickerLineSection tickers={tickers} />}

        <ShopsSectionWrapper>
          <ShopsSection shops={otherStore ?? []} />
        </ShopsSectionWrapper>

        <InformationSectionWrapper>
          <InformationSection
            address={activeRestaurants?.address ?? ''}
            workingTime={timetable ?? ''}
            phone={phone ?? ''}
          />
        </InformationSectionWrapper>

        {setSlider(gallerySlider ?? []).length !== 0 && (
          <SliderWrapper>
            <SliderInner>
              <SectionSlider gallery={setSlider(gallerySlider ?? [])} />
            </SliderInner>
          </SliderWrapper>
        )}

        {galleryBottom?.length > 0 && (
          <GalleryBlockWrapper>
            <GalleryBlock gallery={galleryBottom ?? []} />
          </GalleryBlockWrapper>
        )}

        {activeRestaurants?.vacancies.length !== 0 ? (
          <VacancySectionWrapper>
            <VacancySection
              vacancies={activeRestaurants?.vacancies}
              title={restaurantTitle}
            />
          </VacancySectionWrapper>
        ) : null}
      </Inner>
      <Subscription background={colors.gray50} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: hidden;
`;

const Inner = styled.div`
  margin-bottom: 270px;

  ${media.tablet(css`
    margin-bottom: 100px;
  `)}

  ${media.mobile(css`
    margin-bottom: 118px;
  `)}
`;

const ShopsSectionWrapper = styled.div`
  margin: 72px 0 122px;

  ${media.laptop(css`
    margin: 52px 0 90px;
  `)}

  ${media.tablet(css`
    margin: 15px 0 50px;
  `)}

  ${media.mobile(css`
    margin: 5px 0 50px;
  `)}
`;

const InformationSectionWrapper = styled.div`
  margin: 122px 0;

  ${media.laptop(css`
    margin: 72px 0;
  `)}

  ${media.tablet(css`
    margin: 53px 0;
  `)}

  ${media.mobile(css`
    margin: 70px 0 40px;
  `)}
`;

const SliderWrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  position: relative;

  &:before {
    display: block;
    content: '';
    padding-bottom: calc(100vw / 2);

    @media (min-width: 1920px) {
      padding-bottom: 960px;
    }

    ${media.tablet(css`
      padding-bottom: 61.459%;
    `)}

    ${media.mobile(css`
      padding-bottom: min(141.67%, 510px);
    `)}
  }
`;

const SliderInner = styled.div`
  position: absolute;
  content: '';
  width: calc(100vw - 0.375rem);
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 1921px) {
    width: calc(1920px - 0.375rem);
  }
`;

const GalleryBlockWrapper = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 40px;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const VacancySectionWrapper = styled.div`
  margin: 120px auto 0;

  ${media.laptop(css`
    margin-top: 80px;
  `)}

  ${media.tablet(css`
    margin-top: 50px;
  `)}

  ${media.mobile(css`
    margin-top: 70px;
  `)}
`;

export default RestaurantsSingle;
