import React from 'react';
import styled, { css } from 'styled-components';

import SectionPreview from '@/modules/Home/components/SectionPreview';
import { media } from '@/utils/mixin';
import { ImageThumbnail, StringField } from '@/typings/model';
import { colors } from '@/constants/theme';

import Offers from './Offers';

type Props = {
  magazineTitle: StringField;
  magazineButtonLabel: StringField;
  magazineButtonUrl: StringField;
  specialOffers?: Array<{
    image: {
      fifth: ImageThumbnail;
      first: ImageThumbnail;
      fourth: ImageThumbnail;
      second: ImageThumbnail;
      sixth: ImageThumbnail;
      third: ImageThumbnail;
    };
    title: StringField;
    link: StringField;
    design: StringField;
  }>;
};

function Magazine({
  magazineTitle,
  magazineButtonUrl,
  magazineButtonLabel,
  specialOffers,
}: Props) {
  return (
    <Component>
      <Inner>
        <Left>
          {specialOffers?.map((offersItem, index) => {
            if (index !== 0) return false;
            return (
              <Offers
                key={index}
                image={
                  offersItem.image
                    ? offersItem.image.first
                      ? offersItem.image.first
                      : null
                    : null
                }
                title={offersItem.title}
                link={offersItem.link}
                design="middle"
              />
            );
          })}
        </Left>
        <Right>
          <SectionPreview
            title={magazineTitle}
            buttonLabel={magazineButtonLabel}
            buttonUrl={magazineButtonUrl}
            isBlackTheme={true}
          />
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  padding: 25px 0 210px;
  background: ${colors.white};
  position: relative;

  &:before {
    position: absolute;
    content: '';
    top: -5px;
    bottom: 0;
    left: -3000px;
    right: -3000px;
    background: ${colors.white};
  }

  ${media.laptop(css`
    padding: 0 0 176px;
  `)}

  ${media.mobile(css`
    padding: 0 0 50px;
  `)}
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobile(css`
    display: block;
    margin: 0 -20px;
  `)}
`;

const Left = styled.div`
  flex: 1 1 auto;
  max-width: 784px;
  margin-right: 32px;

  ${media.laptop(css`
    margin-right: 60px;
    max-width: 588px;
    width: 100%;
  `)}

  ${media.tabletSmall(css`
    flex: 0 0 422px;
    margin-right: 36px;
    max-width: 442px;
  `)}
  
  ${media.mobile(css`
    max-width: 100vw;
    margin: 0;
  `)}
`;

const Right = styled.div`
  position: relative;
  margin-top: 92px;
  max-width: 730px;

  ${media.laptop(css`
    margin-top: 80px;
    max-width: 552px;
  `)}

  ${media.tablet(css`
    margin-top: 50px;
  `)}
  
  ${media.tabletSmall(css`
    max-width: calc(100% - 455px);
    margin-top: 0;
  `)}
  
  ${media.mobile(css`
    margin: 50px 20px 0;
    max-width: 100%;
  `)}
`;

export default Magazine;
