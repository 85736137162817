import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { ImageThumbnail, StringField } from '@/typings/model';
import { media } from '@/utils/mixin';
import { breakpoints } from '@/constants/theme';

import Magazine from './components/Magazine';
import Discount from './components/Discount';
import App from './components/App';
import DiscountTablet from './components/DiscountTablet';

type Props = {
  magazineTitle: StringField;
  magazineButtonLabel: StringField;
  magazineButtonUrl: StringField;

  discountTitle: StringField;
  discountButtonLabel: StringField;
  discountButtonUrl: StringField;
  discountBlockquote: StringField;
  discountImage?: ImageThumbnail;
  discountStickerLabel: StringField;
  discountStickerUrl: StringField;

  mobileTitle: StringField;
  mobileButtonLabel: StringField;
  mobileButtonUrl: StringField;
  mobileImage?: ImageThumbnail;
  mobileImage1?: ImageThumbnail;
  mobileImage2?: ImageThumbnail;
  mobileImage3?: ImageThumbnail;
  specialOffers?: Array<{
    image: {
      fifth: ImageThumbnail;
      first: ImageThumbnail;
      fourth: ImageThumbnail;
      second: ImageThumbnail;
      sixth: ImageThumbnail;
      third: ImageThumbnail;
    };
    title: StringField;
    link: StringField;
    design: StringField;
  }>;
};

function SpecialOffers({
  magazineButtonLabel,
  magazineButtonUrl,
  magazineTitle,
  discountBlockquote,
  discountButtonLabel,
  discountButtonUrl,
  discountImage,
  discountTitle,
  discountStickerLabel,
  discountStickerUrl,
  mobileButtonLabel,
  mobileButtonUrl,
  mobileImage,
  mobileImage1,
  mobileImage2,
  mobileImage3,
  mobileTitle,
  specialOffers,
}: Props) {
  const isTablet = useMedia(`(max-width: ${breakpoints.laptop - 1}px)`);

  return (
    <Component>
      <SpecialOffersContainer>
        <Inner>
          <Magazine
            magazineTitle={magazineTitle}
            magazineButtonLabel={magazineButtonLabel}
            magazineButtonUrl={magazineButtonUrl}
            specialOffers={specialOffers}
          />
          {isTablet ? (
            <DiscountTablet
              discountTitle={discountTitle}
              discountButtonLabel={discountButtonLabel}
              discountButtonUrl={discountButtonUrl}
              discountBlockquote={discountBlockquote}
              discountImage={discountImage}
              specialOffers={specialOffers}
              discountStickerLabel={discountStickerLabel}
              discountStickerUrl={discountStickerUrl}
            />
          ) : (
            <Discount
              discountTitle={discountTitle}
              discountButtonLabel={discountButtonLabel}
              discountButtonUrl={discountButtonUrl}
              discountBlockquote={discountBlockquote}
              discountImage={discountImage}
              specialOffers={specialOffers}
              discountStickerLabel={discountStickerLabel}
              discountStickerUrl={discountStickerUrl}
            />
          )}

          <App
            mobileTitle={mobileTitle}
            mobileButtonLabel={mobileButtonLabel}
            mobileButtonUrl={mobileButtonUrl}
            mobileImage={mobileImage}
            mobileImage1={mobileImage1}
            mobileImage2={mobileImage2}
            mobileImage3={mobileImage3}
            specialOffers={specialOffers}
          />
        </Inner>
      </SpecialOffersContainer>
    </Component>
  );
}

const Component = styled.div``;

const SpecialOffersContainer = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 40px;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;
const Inner = styled.div``;

export default SpecialOffers;
