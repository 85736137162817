import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { colors } from '@/constants/theme';
import { ImageThumbnail, StringField } from '@/typings/model';
import { ReactComponent as ArrowLink } from '@/assets/svg/arrow-link.svg';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import Link from '@/components/Link';

type Props = {
  image?: ImageThumbnail;
  title?: StringField;
  text: StringField;
  link: StringField;
  isLink?: boolean;
  isSlider?: boolean;
};

function BannerCard({ image, title, link, text, isLink, isSlider }: Props) {
  return (
    <Component isLink={isLink} isSlider={isSlider}>
      {image ? (
        <BannerCardBackground>
          <Picture mobileSmall={convertThumbnailToPictureImage(image)} />
        </BannerCardBackground>
      ) : null}

      <BannerCardLink to={link ?? ''}>
        <BannerCardInner>
          {title ? <BannerCardTitle>{title}</BannerCardTitle> : null}
          {text ? (
            <BannerCardTextWrapper isLink={isLink}>
              <BannerCardText>{text}</BannerCardText>
            </BannerCardTextWrapper>
          ) : null}
          {isLink ? (
            <BannerCardLinkPointer>
              <ArrowLink />
            </BannerCardLinkPointer>
          ) : null}
        </BannerCardInner>
      </BannerCardLink>
    </Component>
  );
}

const BannerCardLinkPointer = styled.div`
  width: 54px;
  height: 54px;
  border-radius: 100%;
  background: ${colors.white};

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 30px;
  right: 30px;
  transition: all 0.3s linear;

  svg {
    pointer-events: none;
  }

  ${media.laptop(css`
    width: 36px;
    height: 36px;
    bottom: 24px;
    right: 24px;

    svg {
      width: 16px;
      height: 16px;
    }
  `)}

  ${media.tablet(css`
    width: 24px;
    height: 24px;

    svg {
      width: 10px;
      height: 10px;
    }
  `)}
  
  ${media.tabletSmall(css`
    width: 30px;
    height: 30px;

    svg {
      width: 12px;
      height: 12px;
    }
  `)}
  
  ${media.mobile(css`
    position: absolute;
    bottom: 150px;
    right: 20px;
    width: 57px;
    height: 57px;

    svg {
      width: 22px;
      height: 22px;
    }
  `)}
`;

const BannerCardBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background: ${colors.grayLight};
  will-change: transform;
  transition: all 0.3s linear;

  div,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;

    ${media.tabletSmall(css`
      object-fit: cover;
    `)}
  }
`;

const BannerCardInner = styled.div`
  position: relative;
  padding: 30px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${media.laptop(css`
    padding: 24px;
  `)}

  ${media.mobileLarge(css`
    padding: 24px 20px 24px;
  `)}
`;

const BannerCardTitle = styled.span`
  display: block;
  margin-bottom: 48px;
  font-weight: bold;
  font-size: 154px;
  line-height: 142px;
  text-transform: uppercase;
  color: ${colors.white};

  ${media.laptop(css`
    margin-bottom: 30px;
    font-size: 104px;
    line-height: 96px;
  `)}

  ${media.tablet(css`
    margin-bottom: 21px;
    font-size: 77px;
    line-height: 71px;
    word-break: break-word;
  `)}
  
  ${media.mobile(css`
    font-size: 60px;
    line-height: 58px;
  `)}
`;

const BannerCardTextWrapper = styled.div<{ isLink?: boolean }>`
  width: 100%;

  ${(props) =>
    props.isLink
      ? css`
          max-width: 87%;

          ${media.mobile(css`
            max-width: 100%;
          `)}
        `
      : ''}
`;

const BannerCardText = styled.span`
  font-weight: bold;
  background: ${colors.white};
  padding: 0 6px;

  font-size: 36px;
  line-height: 48px;
  color: ${colors.black};
  -webkit-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;

  ${media.laptop(css`
    padding: 0 4px;
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 26px;
    padding: 0 2px;
  `)}
  
  ${media.mobile(css`
    font-size: 24px;
    line-height: 31px;
  `)}
`;

const BannerCardLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const Component = styled.div<{ isLink?: boolean; isSlider?: boolean }>`
  position: relative;
  height: 100%;
  overflow: hidden;

  ${(props) =>
    props.isLink
      ? css`
          &:hover {
            ${BannerCardBackground} {
              transform: scale(1.15) translate3d(0, 0, 0);
            }
          }
        `
      : ''}

  ${(props) =>
    props.isSlider
      ? css`
          ${BannerCardInner} {
            ${media.mobileLarge(css`
              padding-bottom: 54px;
            `)}
          }
        `
      : ''}
`;

export default BannerCard;
