import React, { forwardRef, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { gsap } from 'gsap';

import SectionPreview from '@/modules/Home/components/SectionPreview';
import { media } from '@/utils/mixin';
import mapUrl from '@/assets/images/map/map.png';
import mapUrl2x from '@/assets/images/map/map@2x.png';
import mapUrlWebp from '@/assets/images/map/map.webp';
import mapUrlWebp2x from '@/assets/images/map/map@2x.webp';
import shoppingCartUrl from '@/assets/images/map/shopping-cart.png';
import shoppingCartUrl2x from '@/assets/images/map/shopping-cart@2x.png';
import shoppingCartUrlWebp from '@/assets/images/map/shopping-cart.webp';
import shoppingCartUrlWebp2x from '@/assets/images/map/shopping-cart@2x.webp';
import Picture from '@/components/Picture';
import { StringField } from '@/typings/model';

type Props = {
  shopsTitle: StringField;
  shopsButtonLabel: StringField;
  shopsButtonUrl: StringField;
};

const NewsMap = forwardRef<HTMLDivElement, Props>(function NewsMap(props, ref) {
  const { shopsButtonUrl, shopsTitle, shopsButtonLabel } = props;

  const mapRef = useRef<HTMLDivElement | null>(null);
  const shoppingCartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }
    let tween: gsap.core.Tween;
    const delayedCall = gsap.delayedCall(0, () => {
      if (!mapRef.current) {
        return;
      }
      tween = gsap.to(mapRef.current, {
        yPercent: -70,
        ease: 'linear',
        scrollTrigger: {
          trigger: '#gsap-news',
          start: 'top top',
          scrub: true,
        },
      });
    });

    return () => {
      tween?.kill();
      delayedCall.kill();
    };
  }, []);

  return (
    <Component>
      <Map ref={mapRef}>
        <MapInner>
          <Picture
            mobileSmall={{
              src: mapUrl,
              src2x: mapUrl2x,
              webp: mapUrlWebp,
              webp2x: mapUrlWebp2x,
            }}
          />
        </MapInner>
      </Map>

      <Inner>
        <SectionPreview
          ref={ref}
          title={shopsTitle}
          buttonLabel={shopsButtonLabel}
          buttonUrl={shopsButtonUrl}
        />
      </Inner>

      <ShoppingCart ref={shoppingCartRef}>
        <ShoppingCartInner>
          <Picture
            mobileSmall={{
              src: shoppingCartUrl,
              src2x: shoppingCartUrl2x,
              webp: shoppingCartUrlWebp,
              webp2x: shoppingCartUrlWebp2x,
            }}
          />
        </ShoppingCartInner>
      </ShoppingCart>
    </Component>
  );
});

const Component = styled.div`
  padding: 54px 0 714px;
  margin-left: 136px;
  }

  ${media.laptop(css`
    padding: 41px 0 452px;
    margin-left: 144px;
  `)}
  
    ${media.tabletSmall(css`
      margin-left: 60px;
    `)}
    
    ${media.mobile(css`
      padding-bottom: 140px;
      margin-left: 0;
    `)}
   
`;

const Map = styled.div`
  position: absolute;
  content: '';
  top: -69px;
  right: 0;
  width: 100%;
  max-width: 63.3vw;
  overflow: hidden;

  @media (min-width: 1921px) {
    right: 0;
    max-width: 43.75vw;
  }
  @media (min-width: 3000px) {
    max-width: 35vw;
  }

  ${media.laptop(css`
    top: -58px;
    max-width: 65.5%;
  `)}

  ${media.mobile(css`
    top: -58px;
    max-width: 100%;
  `)}
`;

const MapInner = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;

  &:before {
    display: block;
    content: '';
    padding-bottom: 95%;
    ${media.laptop(css`
      padding-bottom: 94%;
    `)}
  }

  div {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  img,
  picture {
    width: 100%;
    height: 100%;
  }
`;

const Inner = styled.div`
  max-width: 530px;
  position: relative;

  ${media.laptop(css`
    max-width: 400px;
  `)}

  ${media.tabletSmall(css`
    max-width: 360px;
  `)}
  
  ${media.mobile(css`
    max-width: 220px;
  `)}
`;

const ShoppingCart = styled.div`
  position: absolute;
  top: 485px;
  right: 107px;
  width: 690px;
  height: 580px;
  ${media.laptop(css`
    width: 455px;
    height: 420px;
    top: 244px;
    right: -10px;
  `)}

  ${media.tabletSmall(css`
    width: 260px;
    height: 260px;
    top: 280px;
    right: -10px;
  `)}
  
  ${media.mobile(css`
    width: 200px;
    height: 200px;
    top: 140px;
    right: -10px;
  `)}
`;
const ShoppingCartInner = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;

  &:before {
    display: block;
    content: '';
    padding-bottom: 100%;
  }

  div {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  img,
  picture {
    width: 100%;
    height: 100%;
  }
`;

export default NewsMap;
