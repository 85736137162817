import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import Knife from '@/components/Sticker/Knife';
import { media } from '@/utils/mixin';
import { StringField } from '@/typings/model';

import FeaturesCard from './components/FeaturesCard';

interface Props {
  featuresTitle?: StringField;
  features: Array<{
    text: StringField;
    title: StringField;
  }>;
}

function Features({ features, featuresTitle }: Props) {
  return (
    <Component>
      <Inner>
        <Top>
          <Title>
            {featuresTitle ? featuresTitle : `Особенности производства`}
          </Title>
        </Top>
        <Content>
          {features.map((feature, index) => {
            return (
              <FeaturesCard
                key={index}
                title={feature.title ?? ''}
                description={feature.text ?? ''}
              />
            );
          })}
        </Content>
      </Inner>
      <KnifeWrapper>
        <Knife />
      </KnifeWrapper>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1680px;
  margin: 120px auto;
  padding: 0 40px;
  position: relative;

  ${media.tablet(css`
    padding: 0 20px;
    margin: 70px 0;
  `)}
`;

const Inner = styled.div``;

const Top = styled.div`
  margin-bottom: 16px;

  ${media.tablet(css`
    margin-bottom: 0;
  `)}
`;
const Content = styled.div``;

const Title = styled.h2`
  max-width: 784px;
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;
  color: ${colors.black};

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    max-width: 556px;
    font-size: 48px;
    line-height: 56px;
  `)}
  
  ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)}
`;

const KnifeWrapper = styled.div`
  position: absolute;
  bottom: 84%;
  right: 137px;
  z-index: 3;
  width: 442px;
  height: 560px;

  ${media.laptop(css`
    bottom: 66.5%;
    right: 120px;
    width: 380px;
    height: 480px;
  `)}

  ${media.tablet(css`
    bottom: 81%;
    right: -36px;
    width: 210px;
    height: 266px;
  `)}
  
  ${media.mobile(css`
    bottom: 92%;
    right: -40px;
    width: 152px;
    height: 192px;
  `)}
`;

export default Features;
